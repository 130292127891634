$reg: 'GothamProRegular' , sans-serif;
$med: 'GothamProMedium' , sans-serif;
$black: 'GothamProBlack' , sans-serif;
$light: 'GothamProLight' , sans-serif;

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	text-decoration: none;
	list-style-type: none; }

body {
	min-width: 320px;
	background: #fff; }
img {
	max-width: 100%; }
p,li {
	color: #000;
	font: 18px $reg; }
/* ====================================== SWIPER ===================================== */
.swiper-container {
	width: 100%;
	height: 100%;
	margin-left: auto;
	margin-right: auto; }
.swiper-slide {
	display: flex;
	justify-content: center;
	align-items: center; }
.swiper-wrapper {
	align-items: center; }
.swiper-button-next, .swiper-button-prev {
	background: none;
	width: 11px;
	height: 19px;
	margin-top: -9.5px; }
.swiper-button-prev {
	transform: rotate(-180deg); }

#menu a {
	color: #fff;
	font: 14px $reg; }
.header__menu-open {
	font: 14px $reg;
	margin-top: 15px;
	position: static;
	svg {
		margin-left: 10px; } }
/* ====================================== SWIPER ===================================== */

/* ====================================== HEADER START  ==============================  */
.header {
	background: #fff;
	position: sticky;
	top: 0;
	z-index: 2;
	&__top {
		border-bottom: 1px solid rgba(184,184,184,.3); }
	&__contacts {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 20px 0 15px;
		div {
			display: flex;
			align-items: center;
			margin-right: 30px;
			font: 19px $reg;
			color: #000; }
		img {
			margin-right: 10px; } }
	&__main {
		border-bottom: 10px solid #2b4873; }
	&__left-block {
		display: flex;
		flex-direction: column;
		span {
			font: 0px $reg;
			color: #000;
			transition: all .3s ease; }
		&.active span {
			font-size: 16px; } }
	&__row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 15px 0; }
	&__menu {
		display: flex;
		align-items: center;
		a {
			color: #4470b4;
			font: 18px $reg;
			display: inline-block;
			margin-right: 30px;
			position: relative;
			&:before {
				content: '';
				display: block;
				width: 100%;
				height: 1px;
				background-color: #4470b4;
				position: absolute;
				left: 0;
				top: -40px;
				transition: all .3s ease;
				opacity: 0;
				visibility: hidden; }
			&:hover {
				&:before {
					opacity: 1;
					visibility: visible; } } }
		&-subLink {
			position: relative;
			&:hover {
				.header__subMenu {
					opacity: 1;
					visibility: visible; }
				&:after {
					transform: rotate(180deg); } }
			&:after {
				content: '';
				display: inline-block;
				width: 12px;
				height: 6px;
				position: absolute;
				left: 50%;
				top: 35px;
				margin-left: -12px;
				transition: all .3s linear;
				background: url(../img/angle.png) no-repeat center; } } }
	&__subMenu {
		position: absolute;
		background: #fff;
		width: 323px;
		box-shadow: 0 2px 9px rgba(0, 0, 0, 0.57);
		padding: 20px;
		z-index: 1;
		top: 0px;
		margin-top: 60px;
		opacity: 0;
		visibility: hidden;
		transition: all 1s ease;
		a {
			position: relative;
			margin-bottom: 15px;
			&:before {
				content: '';
				width: 25px;
				height: 1px;
				position: absolute;
				left: 0;
				bottom: 0;
				background: rgba(184,184,184,.3);
				display: inline-block;
				opacity: 1;
				visibility: visible;
				top: 25px; } }
		li:last-of-type a:before {
			display: none; }
		&-articles {
			left: -170px; } } }
.logo {
	transition: all .3s ease;
	width: 100%;
	&.active {
		width: 220px;
		transition: all .3s ease; } }
/* ====================================== HEADER END  ==============================  */

/* ====================================== VIDEO START  ==============================  */
.video {
	max-height: 653px;
	height: 100%;
	overflow: hidden;
	z-index: 1;
	position: relative;
	&__top {
		background-color: #4470b4;
		text-align: center;
		color: #fff;
		font: 32px $med;
		padding: 13px 0; }
	&__btn {
		width: 391px;
		height: 60px;
		background-color: #4470b4;
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		outline: none;
		cursor: pointer;
		font: 24px $reg;
		color: #fff;
		transition: all .3s ease;
		&:hover {
			transform: scale(.9); } }
	&__title {
		color: #fff;
		margin-bottom: 65px;
		font: 60px $reg; }
	&__info {
		background: rgba(0,0,0,.5);
		position: absolute;
		z-index: 1;
		right: 0;
		bottom: 0;
		height: calc(100% - 62px);
		width: 645px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-left: 100px; } }
video {
	width: 100%;
	z-index: 1; }
/* ====================================== VIDEO END  ==============================  */

/* ====================================== GOODS START  ==============================  */
.goods {
	background: url(../img/line4.png) no-repeat 100% 0% , url(../img/line3.png) no-repeat 5% 0% , url(../img/line2.png) no-repeat 10% 5% , url(../img/line1.png) no-repeat 100% 2%,#fff;
	padding: 105px 0 160px;
	&__title {
		margin-bottom: 75px;
		text-align: center;
		font: 48px $reg;
		color: #191919;
		text-transform: uppercase; }
	&__item {
		display: flex;
		align-items: center;
		margin-bottom: 80px;
		&:nth-child(odd) {
			justify-content: flex-end;
			.goods__info {
				margin-left: 0;
				margin-right: 65px; }
			.goods__item-name {
				text-align: right; }
			.goods__item-more {
				margin-left: auto; } }
		&-name {
			color: #191919;
			font: 40px $reg; }
		&-price {
			font: 58px $reg;
			color: #4470b4;
			margin-bottom: 45px; }
		&-more {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 391px;
			height: 63px;
			background-color: #4470b4;
			color: #fff;
			font: 24px $reg;
			transition: all .3s ease;
			&:hover {
				background: #03a9f4; } } }
	&__info {
		margin-left: 65px; }
	&__text {
		font: 16px $reg;
		color: #191919;
		max-width: 1300px;
		padding: 0 15px;
		margin: 0 auto 25px; }
	&__more {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 10px;
		text-transform: uppercase;
		color: #191919;
		font: 16px $reg; } }
/* ====================================== GOODS END  ================================  */

/* ====================================== FOOTER START  ================================  */
.footer {
	background-color: #3e4347;
	border-top: 10px solid #4470b4;
	.header__menu-link a {
		color: #ffffff;
		margin: 0;
		&:before {
			display: none; } }
	.header__menu {
		justify-content: space-between; }
	&__top {
		padding: 40px 0 35px;
		border-bottom: 1px solid rgba(255,255,255,.3); }
	&__bottom {
		padding: 35px 0; }
	&__container {
		max-width: 1330px;
		margin: 0 auto;
		padding: 0 15px; }
	&__info {
		font: 16px/24px $reg;
		color: rgba(255,255,255,.4); }
	&__item {
		display: flex;
		align-items: center;
		p {
			font: 16px/24px $reg;
			color: rgba(255,255,255,.4); } }
	&__logo {
		padding-right: 15px;
		margin-right: 15px;
		border-right: 1px solid rgba(255,255,255,.4); }
	&__social {
		display: flex;
		align-items: center;
		margin-left: 120px;
		a {
			width: 36px;
			height: 36px;
			background-color: #ffffff;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: all .3s linear;
			color: #3e4347;
			margin: 0 5px;
			&:hover {
				background: #03a9f4;
				color: #fff; } } }
	&__row {
		display: flex;
		justify-content: space-between;
		align-items: center; }
	&__list {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		li {
			margin-right: 40px;
			&:last-of-type {
				margin-right: 0; } } }
	&__stats {
		display: flex;
		flex-direction: column; } }
/* ====================================== FOOTER END  ==================================  */

/* ====================================== PRODUCTION START  ==================================  */
.production {
	background: #fff;
	padding: 55px 0 90px;
	&__row {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-bottom: 100px; }
	&__item {
		flex-basis: 24%;
		margin: 0 5px 40px;
		text-align: center; }
	&__name {
		font: 24px $med;
		color: #000;
		margin-top: 20px; } }
.productionPage h2 {
	color: #000;
	font: 30px $med;
	margin-bottom: 30px; }
.productionPage p {
	margin-bottom: 30px; }
.productionPage ul {
	margin-bottom: 40px; }
/* ====================================== PRODUCTION END  ==================================  */

/* ====================================== PAGINATION START  ==================================  */
.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	a {
		color: #000000;
		font: 18px $reg;
		transition: all .3s linear;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		margin: 0 5px;
		&.active {
			color: #4470b4;
			font-size: 30px; }
		&:hover {
			color: #4470b4; } }
	.prev,.next {
		width: 43px;
		height: 43px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid #b6b6b6;
		background-color: #ffffff;
		color: #b6b6b6;
		&:hover {
			border-color: #4470b4; } }
	.prev {
		margin-right: 40px; }
	.next {
		margin-left: 40px; } }
/* ====================================== PAGINATION END  ==================================  */

/* ====================================== ARTICLES START  ==================================  */
.articles {
	background: #fff;
	padding: 55px 0 90px;
	&__row {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-bottom: 70px; }
	&__item {
		flex-basis: 31%;
		margin: 0 5px 20px;
		padding-bottom: 25px;
		text-align: center;
		border-bottom: 1px solid rgba(184,184,184,.3);
		position: relative;
		&:hover {
			.articles__image:before {
				opacity: 1;
				visibility: visible; } } }
	&__image {
		position: relative;
		&:before {
			content: 'Читать';
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			font: 24px $med;
			text-transform: uppercase;
			position: absolute;
			padding-top: 60px;
			left: 0;
			top: 0;
			background: url(../img/book.png) no-repeat 50% 30% , rgba(0,0,0,.6);
			width: 100%;
			height: 100%;
			opacity: 0;
			visibility: hidden;
			transition: all .3s linear; } }
	&__bottom {
		display: flex;
		margin-top: 10px;
		justify-content: space-between; }
	&__name {
		font: 18px $med;
		color: #000;
		text-align: left; }
	&__date {
		font: 16px $reg;
		color: #4470b4; } }
/* ====================================== ARTICLES END  ==================================  */

/* ====================================== FEEDBACK START  ==================================  */
.feedback__item {
	outline: none;
	margin: 0 4px 25px; }
/* ====================================== FEEDBACK END  ==================================  */

/* ====================================== WORKS START  ==================================  */
.works__sort {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 50px;
	li {
		font: 18px $reg;
		color: #000;
		padding: 10px 13px;
		border-radius: 20px;
		border: 1px solid transparent;
		cursor: pointer;
		transition: all .3s ease;
		&.active {
			border-color: #4470b4; } } }
.work__item {
	position: relative;
	flex-basis: 33.33%;
	overflow: hidden;
	height: auto;
	&:hover .work__info {
		top: 0;
		opacity: 1;
		visibility: visible; } }
.work__info {
	background-color: rgba(0, 0, 0, 0.79);
	text-align: center;
	padding: 0 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: all .3s linear;
	position: absolute;
	left: 0;
	top: -100%;
	width: 100%;
	height: 99%;
	opacity: 0;
	visibility: hidden; }
.work__name {
	font: 700 24px $reg;
	color: #fff;
	text-transform: uppercase;
	margin-bottom: 25px; }
.work__desc {
	font: 18px $reg;
	color: #fff; }
.work__btn {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 90px auto 0;
	width: 391px;
	height: 63px;
	background-color: #4470b4;
	color: #fff;
	font: 24px $reg; }
.workCategory__item {
	display: flex;
	padding-bottom: 25px;
	margin-bottom: 25px;
	border-bottom: 1px solid rgba(184,184,184,.5); }
.workCategory__info {
	display: flex;
	flex-direction: column;
	justify-content: space-between; }
.workCategory__image {
	margin-right: 40px;
	max-width: 449px;
	flex-basis: 100%; }
.workCategory__title {
	font: 24px $med;
	color: #000;
	margin-bottom: 15px; }
.workCategory__name {
	display: inline-block;
	margin-bottom: 30px;
	font: 18px $reg;
	color: #4470b4;
	font-style: italic;
	text-decoration: underline; }
.workCategory__desc {
	font: 18px $reg;
	color: #000;
	margin-bottom: 30px; }
.workCategory__more {
	width: 252px;
	height: 42px;
	border: 1px solid #4470b4;
	background-color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	transition: all .3s ease;
	color: #4470b4;
	font: 18px $reg;
	&:hover {
		background: #4470b4;
		color: #fff; } }
/* ====================================== WORKS END  ==================================  */

/* ====================================== WORKS ITEM START  ==================================  */
.breadcrumbs {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin: 0 0 25px 0;
	padding: 0 0 25px 30px;
	border-bottom: 1px solid rgba(184,184,184,.5);
	a {
		font: 300 16px $reg;
		color: #4470b4;
		padding-right: 20px;
		display: inline-block;
		position: relative;
		transition: all .3s ease;
		&:hover {
			color: #000; }
		&:after {
			content: '-';
			display: inline-block;
			position: absolute;
			right: 7px; } }
	li:last-of-type a:after {
		display: none; } }
.projectSwitch {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0 0 25px 0;
	padding: 0 0 25px 0px;
	border-bottom: 1px solid rgba(184,184,184,.5);
	a {
		color: #4470b4;
		font: 18px $reg;
		width: 277px;
		height: 43px;
		border: 1px solid #4470b4;
		background-color: #ffffff;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all .3s ease;
		&:hover {
			background: #4470b4;
			color: #fff; } } }
.workItem__image {
	margin-right: 30px;
	max-width: 656px;
	flex-basis: 100%; }
.workItem__more {
	width: 510px;
	height: 63px;
	background-color: #4470b4;
	border: 1px solid #4470b4;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all .3s ease;
	color: #fff;
	font: 24px $reg;
	margin-top: 30px;
	&:hover {
		background: #fff;
		color: #4470b4; } }
.workItem__information .workCategory__desc {
	margin-bottom: 60px; }
.workItem__block {
	border: none;
	margin-bottom: 0;
	padding-bottom: 0; }
.workItem__row {
	margin-bottom: 80px; }
.workItem__other {
	color: #000;
	font: 24px $med;
	margin: 0 0 25px 0;
	padding: 0 0 25px 30px;
	border-bottom: 1px solid rgba(184,184,184,.5); }
.workItem__slider {
	position: relative; }
.workItem__slider .articles__item {
	flex-basis: 100%;
	border: none; }
.workItem__slider .articles__date {
	flex-basis: 35%; }
.workItem__slider .articles__image:before {
	content: 'Смотреть';
	background: url(../img/eye.png) no-repeat 50% 30% , rgba(0,0,0,.6); }
.button-prev,.button-next {
	width: 43px;
	height: 43px;
	background-color: #4470b4;
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;
	border: none;
	cursor: pointer;
	position: absolute;
	top: 28%;
	z-index: 2; }
.button-next {
	right: 5px; }
.button-prev {
	left: 5px; }
/* ====================================== WORKS ITEMS END  ==================================  */

/* ====================================== PROD ITEM START  ==================================  */
.prod__title {
	text-align: center;
	margin-bottom: 40px;
	font: 30px $med;
	color: #000; }
.prodItem__row {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap; }
.prodItem__block {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-basis: 25%;
	margin-bottom: 65px; }
.prodItem__image {
	margin-bottom: 20px; }
.prodItem__name {
	font: 18px $reg;
	color: #000000; }
.prodItem__row1 {
	display: flex;
	justify-content: space-between;
	margin-bottom: 90px; }
.prodDesc__image {
	max-width: 659px;
	flex-basis: 100%;
	margin-right: 45px; }
.prod__list li {
	position: relative;
	font: 18px $reg;
	color: #000;
	margin-bottom: 20px;
	&:before {
		content: '';
		width: 10px;
		height: 10px;
		background-color: #4470b4;
		position: absolute;
		left: -20px;
		top: 5px; } }
.prodItem__row2 {
	display: flex;
	justify-content: space-between; }
.prodInfo__image {
	max-width: 659px;
	flex-basis: 100%;
	margin-left: 50px; }
.prodItem__information {
	flex-basis: 100%;
	p {
		margin-bottom: 15px; } }
.prodItem__lastRow {
	margin-bottom: 60px; }
/* ====================================== PROD ITEM END  ==================================  */

/* ====================================== CONTACTS ITEM START  ==================================  */
#map {
	height: 325px; }
.contacts__row {
	display: flex;
	justify-content: space-between; }
.contacts__title {
	font: 700 24px $reg;
	color: #000000;
	margin-bottom: 20px; }
.contacts__list li {
	color: #000;
	font: 24px $reg;
	span {
		font: 24px $light;
		padding-left: 10px; } }
.contacts__border {
	border-left: 2px solid #4470b4;
	padding-left: 25px; }
.contacts__address {
	margin-bottom: 50px;
	li {
		display: flex;
		flex-direction: column;
		font: 24px/24px $med;
		color: #000000;
		margin-bottom: 30px;
		span {
			font: 24px/24px $reg; } } }
.contacts__itemFirst {
	flex-basis: 50%; }
.contacts__email {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	font: 18px $reg;
	width: 389px;
	height: 62px;
	background-color: #4470b4; }
/* ====================================== CONTACTS ITEM END  ==================================  */

/* ====================================== PAYMENTS ITEM START  ==================================  */
.payments p {
	margin-bottom: 30px; }
.payments__title {
	margin-bottom: 25px;
	font: 30px $med;
	color: #000; }
.payments__subTitle {
	margin-bottom: 15px;
	font: 18px $light;
	font-style: italic;
	color: #4470b4; }
.payments__row {
	display: flex;
	justify-content: space-between;
	.sendForm {
		max-width: 410px;
		flex-basis: 100%; } }
.payments__image {
	max-width: 793px;
	flex-basis: 100%; }
.payments__blockTitle {
	color: #4470b4;
	font: 24px $reg;
	margin-bottom: 25px;
	margin-top: 25px; }
label {
	color: #000000;
	font: 16px/30px $reg;
	display: flex;
	align-items: center;
	cursor: pointer; }
.jq-radio {
	max-width: 18px;
	height: 18px;
	border: 1px solid #808080;
	background-color: #ffffff;
	margin-right: 10px;
	flex-basis: 100%;
	transition: all .3s ease;
	border-radius: 50%;
	cursor: pointer;
	&.checked {
		box-shadow: inset 0 0 0 4px #fff , inset 0 0 0 9px #4470b4; } }
.inputs__list {
	display: flex;
	justify-content: space-between;
	padding: 0 45px;
	flex-wrap: wrap;
	label {
		flex-basis: 50%;
		color: #a2a2a2;
		font-size: 15px; }
	.jq-checkbox {
		width: 18px;
		height: 18px;
		border-radius: 4px;
		border: 1px solid #808080;
		background-color: #ffffff;
		transition: all .3s ease;
		margin-right: 10px;
		&.checked {
			background: url(../img/check.png) no-repeat center , #fff; } } }
.inputs__calc {
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin-top: 20px;
	input {
		width: 114px;
		height: 38px;
		border: 1px solid #4470b4;
		background-color: #ffffff;
		font: 18px $reg;
		padding: 0 15px;
		color: rgba(0,0,0,.5); }
	span {
		font: 14px $reg;
		color: #000; }
	.calc__total {
		color: #b8b8b8;
		font: 16px $reg;
		span {
			font: 700 24px $reg;
			color: #000; }
		sup {
			font-size: 16px; } } }
.payments__blockName {
	text-transform: uppercase;
	padding-left: 20px;
	color: #000;
	font: 20px $med;
	margin-bottom: 10px; }
.payments__blockBottom {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	ul {
		flex-basis: 50%;
		margin-bottom: 25px; } }
.jq-checkbox {
	width: 18px;
	height: 18px;
	border-radius: 4px;
	border: 1px solid #808080;
	background-color: #ffffff;
	transition: all .3s ease;
	margin-right: 10px;
	&.checked {
		background: url(../img/check.png) no-repeat center , #fff; } }
.payments__amount {
	color: #b8b8b8;
	padding-left: 5px; }
/* ====================================== PAYMENTS ITEM END  ==================================  */

/* ====================================== MODALS START  ==============================  */
.overlay {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 10;
	display: none; }
.modal {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
	z-index: 11;
	width: 409px;
	height: 441px;
	display: none; }
.sendForm {
	width: 409px;
	height: 441px;
	border: 2px solid #4470b4;
	background-color: #dde9fb;
	padding: 20px;
	&__title {
		text-align: center;
		margin-bottom: 30px;
		font: 24px/30px $med;
		color: #000; }
	&__info {
		text-align: center;
		font: 12px $reg;
		color: #000;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		a {
			font: 12px $reg;
			color: #0000ff; } }
	input {
		font: 16px $reg;
		color: #aaaaaa;
		height: 53px;
		background-color: #ffffff;
		width: 100%;
		margin-bottom: 5px;
		padding: 0 15px;
		&::placeholder {
			color: #aaaaaa;
			font-style: italic; } }
	button {
		margin-top: 10px;
		width: 100%;
		height: 52px;
		background-color: #4470b4;
		margin-bottom: 15px;
		font: 20px $reg;
		color: #fff;
		text-transform: uppercase;
		outline: none;
		border: none;
		cursor: pointer;
		transition: all .3s ease;
		&:hover {
			transform: scale(.9); } } }
/* ====================================== MODALS END  ================================  */
