@charset "UTF-8";
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style-type: none; }

body {
  min-width: 320px;
  background: #fff; }

img {
  max-width: 100%; }

p, li {
  color: #000;
  font: 18px "GothamProRegular", sans-serif; }

/* ====================================== SWIPER ===================================== */
.swiper-container {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto; }

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center; }

.swiper-wrapper {
  align-items: center; }

.swiper-button-next, .swiper-button-prev {
  background: none;
  width: 11px;
  height: 19px;
  margin-top: -9.5px; }

.swiper-button-prev {
  transform: rotate(-180deg); }

#menu a {
  color: #fff;
  font: 14px "GothamProRegular", sans-serif; }

.header__menu-open {
  font: 14px "GothamProRegular", sans-serif;
  margin-top: 15px;
  position: static; }
  .header__menu-open svg {
    margin-left: 10px; }

/* ====================================== SWIPER ===================================== */
/* ====================================== HEADER START  ==============================  */
.header {
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 2; }
  .header__top {
    border-bottom: 1px solid rgba(184, 184, 184, 0.3); }
  .header__contacts {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 0 15px; }
    .header__contacts div {
      display: flex;
      align-items: center;
      margin-right: 30px;
      font: 19px "GothamProRegular", sans-serif;
      color: #000; }
    .header__contacts img {
      margin-right: 10px; }
  .header__main {
    border-bottom: 10px solid #2b4873; }
  .header__left-block {
    display: flex;
    flex-direction: column; }
    .header__left-block span {
      font: 0px "GothamProRegular", sans-serif;
      color: #000;
      transition: all .3s ease; }
    .header__left-block.active span {
      font-size: 16px; }
  .header__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0; }
  .header__menu {
    display: flex;
    align-items: center; }
    .header__menu a {
      color: #4470b4;
      font: 18px "GothamProRegular", sans-serif;
      display: inline-block;
      margin-right: 30px;
      position: relative; }
      .header__menu a:before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #4470b4;
        position: absolute;
        left: 0;
        top: -40px;
        transition: all .3s ease;
        opacity: 0;
        visibility: hidden; }
      .header__menu a:hover:before {
        opacity: 1;
        visibility: visible; }
    .header__menu-subLink {
      position: relative; }
      .header__menu-subLink:hover .header__subMenu {
        opacity: 1;
        visibility: visible; }
      .header__menu-subLink:hover:after {
        transform: rotate(180deg); }
      .header__menu-subLink:after {
        content: '';
        display: inline-block;
        width: 12px;
        height: 6px;
        position: absolute;
        left: 50%;
        top: 35px;
        margin-left: -12px;
        transition: all .3s linear;
        background: url(../img/angle.png) no-repeat center; }
  .header__subMenu {
    position: absolute;
    background: #fff;
    width: 323px;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.57);
    padding: 20px;
    z-index: 1;
    top: 0px;
    margin-top: 60px;
    opacity: 0;
    visibility: hidden;
    transition: all 1s ease; }
    .header__subMenu a {
      position: relative;
      margin-bottom: 15px; }
      .header__subMenu a:before {
        content: '';
        width: 25px;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: rgba(184, 184, 184, 0.3);
        display: inline-block;
        opacity: 1;
        visibility: visible;
        top: 25px; }
    .header__subMenu li:last-of-type a:before {
      display: none; }
    .header__subMenu-articles {
      left: -170px; }

.logo {
  transition: all .3s ease;
  width: 100%; }
  .logo.active {
    width: 220px;
    transition: all .3s ease; }

/* ====================================== HEADER END  ==============================  */
/* ====================================== VIDEO START  ==============================  */
.video {
  max-height: 653px;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  position: relative; }
  .video__top {
    background-color: #4470b4;
    text-align: center;
    color: #fff;
    font: 32px "GothamProMedium", sans-serif;
    padding: 13px 0; }
  .video__btn {
    width: 391px;
    height: 60px;
    background-color: #4470b4;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
    font: 24px "GothamProRegular", sans-serif;
    color: #fff;
    transition: all .3s ease; }
    .video__btn:hover {
      transform: scale(0.9); }
  .video__title {
    color: #fff;
    margin-bottom: 65px;
    font: 60px "GothamProRegular", sans-serif; }
  .video__info {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    height: calc(100% - 62px);
    width: 645px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 100px; }

video {
  width: 100%;
  z-index: 1; }

/* ====================================== VIDEO END  ==============================  */
/* ====================================== GOODS START  ==============================  */
.goods {
  background: url(../img/line4.png) no-repeat 100% 0%, url(../img/line3.png) no-repeat 5% 0%, url(../img/line2.png) no-repeat 10% 5%, url(../img/line1.png) no-repeat 100% 2%, #fff;
  padding: 105px 0 160px; }
  .goods__title {
    margin-bottom: 75px;
    text-align: center;
    font: 48px "GothamProRegular", sans-serif;
    color: #191919;
    text-transform: uppercase; }
  .goods__item {
    display: flex;
    align-items: center;
    margin-bottom: 80px; }
    .goods__item:nth-child(odd) {
      justify-content: flex-end; }
      .goods__item:nth-child(odd) .goods__info {
        margin-left: 0;
        margin-right: 65px; }
      .goods__item:nth-child(odd) .goods__item-name {
        text-align: right; }
      .goods__item:nth-child(odd) .goods__item-more {
        margin-left: auto; }
    .goods__item-name {
      color: #191919;
      font: 40px "GothamProRegular", sans-serif; }
    .goods__item-price {
      font: 58px "GothamProRegular", sans-serif;
      color: #4470b4;
      margin-bottom: 45px; }
    .goods__item-more {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 391px;
      height: 63px;
      background-color: #4470b4;
      color: #fff;
      font: 24px "GothamProRegular", sans-serif;
      transition: all .3s ease; }
      .goods__item-more:hover {
        background: #03a9f4; }
  .goods__info {
    margin-left: 65px; }
  .goods__text {
    font: 16px "GothamProRegular", sans-serif;
    color: #191919;
    max-width: 1300px;
    padding: 0 15px;
    margin: 0 auto 25px; }
  .goods__more {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    text-transform: uppercase;
    color: #191919;
    font: 16px "GothamProRegular", sans-serif; }

/* ====================================== GOODS END  ================================  */
/* ====================================== FOOTER START  ================================  */
.footer {
  background-color: #3e4347;
  border-top: 10px solid #4470b4; }
  .footer .header__menu-link a {
    color: #ffffff;
    margin: 0; }
    .footer .header__menu-link a:before {
      display: none; }
  .footer .header__menu {
    justify-content: space-between; }
  .footer__top {
    padding: 40px 0 35px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
  .footer__bottom {
    padding: 35px 0; }
  .footer__container {
    max-width: 1330px;
    margin: 0 auto;
    padding: 0 15px; }
  .footer__info {
    font: 16px/24px "GothamProRegular", sans-serif;
    color: rgba(255, 255, 255, 0.4); }
  .footer__item {
    display: flex;
    align-items: center; }
    .footer__item p {
      font: 16px/24px "GothamProRegular", sans-serif;
      color: rgba(255, 255, 255, 0.4); }
  .footer__logo {
    padding-right: 15px;
    margin-right: 15px;
    border-right: 1px solid rgba(255, 255, 255, 0.4); }
  .footer__social {
    display: flex;
    align-items: center;
    margin-left: 120px; }
    .footer__social a {
      width: 36px;
      height: 36px;
      background-color: #ffffff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all .3s linear;
      color: #3e4347;
      margin: 0 5px; }
      .footer__social a:hover {
        background: #03a9f4;
        color: #fff; }
  .footer__row {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .footer__list {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }
    .footer__list li {
      margin-right: 40px; }
      .footer__list li:last-of-type {
        margin-right: 0; }
  .footer__stats {
    display: flex;
    flex-direction: column; }

/* ====================================== FOOTER END  ==================================  */
/* ====================================== PRODUCTION START  ==================================  */
.production {
  background: #fff;
  padding: 55px 0 90px; }
  .production__row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 100px; }
  .production__item {
    flex-basis: 24%;
    margin: 0 5px 40px;
    text-align: center; }
  .production__name {
    font: 24px "GothamProMedium", sans-serif;
    color: #000;
    margin-top: 20px; }

.productionPage h2 {
  color: #000;
  font: 30px "GothamProMedium", sans-serif;
  margin-bottom: 30px; }

.productionPage p {
  margin-bottom: 30px; }

.productionPage ul {
  margin-bottom: 40px; }

/* ====================================== PRODUCTION END  ==================================  */
/* ====================================== PAGINATION START  ==================================  */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto; }
  .pagination a {
    color: #000000;
    font: 18px "GothamProRegular", sans-serif;
    transition: all .3s linear;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 0 5px; }
    .pagination a.active {
      color: #4470b4;
      font-size: 30px; }
    .pagination a:hover {
      color: #4470b4; }
  .pagination .prev, .pagination .next {
    width: 43px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #b6b6b6;
    background-color: #ffffff;
    color: #b6b6b6; }
    .pagination .prev:hover, .pagination .next:hover {
      border-color: #4470b4; }
  .pagination .prev {
    margin-right: 40px; }
  .pagination .next {
    margin-left: 40px; }

/* ====================================== PAGINATION END  ==================================  */
/* ====================================== ARTICLES START  ==================================  */
.articles {
  background: #fff;
  padding: 55px 0 90px; }
  .articles__row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 70px; }
  .articles__item {
    flex-basis: 31%;
    margin: 0 5px 20px;
    padding-bottom: 25px;
    text-align: center;
    border-bottom: 1px solid rgba(184, 184, 184, 0.3);
    position: relative; }
    .articles__item:hover .articles__image:before {
      opacity: 1;
      visibility: visible; }
  .articles__image {
    position: relative; }
    .articles__image:before {
      content: 'Читать';
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font: 24px "GothamProMedium", sans-serif;
      text-transform: uppercase;
      position: absolute;
      padding-top: 60px;
      left: 0;
      top: 0;
      background: url(../img/book.png) no-repeat 50% 30%, rgba(0, 0, 0, 0.6);
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      transition: all .3s linear; }
  .articles__bottom {
    display: flex;
    margin-top: 10px;
    justify-content: space-between; }
  .articles__name {
    font: 18px "GothamProMedium", sans-serif;
    color: #000;
    text-align: left; }
  .articles__date {
    font: 16px "GothamProRegular", sans-serif;
    color: #4470b4; }

/* ====================================== ARTICLES END  ==================================  */
/* ====================================== FEEDBACK START  ==================================  */
.feedback__item {
  outline: none;
  margin: 0 4px 25px; }

/* ====================================== FEEDBACK END  ==================================  */
/* ====================================== WORKS START  ==================================  */
.works__sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px; }
  .works__sort li {
    font: 18px "GothamProRegular", sans-serif;
    color: #000;
    padding: 10px 13px;
    border-radius: 20px;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all .3s ease; }
    .works__sort li.active {
      border-color: #4470b4; }

.work__item {
  position: relative;
  flex-basis: 33.33%;
  overflow: hidden;
  height: auto; }
  .work__item:hover .work__info {
    top: 0;
    opacity: 1;
    visibility: visible; }

.work__info {
  background-color: rgba(0, 0, 0, 0.79);
  text-align: center;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all .3s linear;
  position: absolute;
  left: 0;
  top: -100%;
  width: 100%;
  height: 99%;
  opacity: 0;
  visibility: hidden; }

.work__name {
  font: 700 24px "GothamProRegular", sans-serif;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 25px; }

.work__desc {
  font: 18px "GothamProRegular", sans-serif;
  color: #fff; }

.work__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 90px auto 0;
  width: 391px;
  height: 63px;
  background-color: #4470b4;
  color: #fff;
  font: 24px "GothamProRegular", sans-serif; }

.workCategory__item {
  display: flex;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid rgba(184, 184, 184, 0.5); }

.workCategory__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.workCategory__image {
  margin-right: 40px;
  max-width: 449px;
  flex-basis: 100%; }

.workCategory__title {
  font: 24px "GothamProMedium", sans-serif;
  color: #000;
  margin-bottom: 15px; }

.workCategory__name {
  display: inline-block;
  margin-bottom: 30px;
  font: 18px "GothamProRegular", sans-serif;
  color: #4470b4;
  font-style: italic;
  text-decoration: underline; }

.workCategory__desc {
  font: 18px "GothamProRegular", sans-serif;
  color: #000;
  margin-bottom: 30px; }

.workCategory__more {
  width: 252px;
  height: 42px;
  border: 1px solid #4470b4;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all .3s ease;
  color: #4470b4;
  font: 18px "GothamProRegular", sans-serif; }
  .workCategory__more:hover {
    background: #4470b4;
    color: #fff; }

/* ====================================== WORKS END  ==================================  */
/* ====================================== WORKS ITEM START  ==================================  */
.breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 25px 0;
  padding: 0 0 25px 30px;
  border-bottom: 1px solid rgba(184, 184, 184, 0.5); }
  .breadcrumbs a {
    font: 300 16px "GothamProRegular", sans-serif;
    color: #4470b4;
    padding-right: 20px;
    display: inline-block;
    position: relative;
    transition: all .3s ease; }
    .breadcrumbs a:hover {
      color: #000; }
    .breadcrumbs a:after {
      content: '-';
      display: inline-block;
      position: absolute;
      right: 7px; }
  .breadcrumbs li:last-of-type a:after {
    display: none; }

.projectSwitch {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 25px 0;
  padding: 0 0 25px 0px;
  border-bottom: 1px solid rgba(184, 184, 184, 0.5); }
  .projectSwitch a {
    color: #4470b4;
    font: 18px "GothamProRegular", sans-serif;
    width: 277px;
    height: 43px;
    border: 1px solid #4470b4;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s ease; }
    .projectSwitch a:hover {
      background: #4470b4;
      color: #fff; }

.workItem__image {
  margin-right: 30px;
  max-width: 656px;
  flex-basis: 100%; }

.workItem__more {
  width: 510px;
  height: 63px;
  background-color: #4470b4;
  border: 1px solid #4470b4;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s ease;
  color: #fff;
  font: 24px "GothamProRegular", sans-serif;
  margin-top: 30px; }
  .workItem__more:hover {
    background: #fff;
    color: #4470b4; }

.workItem__information .workCategory__desc {
  margin-bottom: 60px; }

.workItem__block {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0; }

.workItem__row {
  margin-bottom: 80px; }

.workItem__other {
  color: #000;
  font: 24px "GothamProMedium", sans-serif;
  margin: 0 0 25px 0;
  padding: 0 0 25px 30px;
  border-bottom: 1px solid rgba(184, 184, 184, 0.5); }

.workItem__slider {
  position: relative; }

.workItem__slider .articles__item {
  flex-basis: 100%;
  border: none; }

.workItem__slider .articles__date {
  flex-basis: 35%; }

.workItem__slider .articles__image:before {
  content: 'Смотреть';
  background: url(../img/eye.png) no-repeat 50% 30%, rgba(0, 0, 0, 0.6); }

.button-prev, .button-next {
  width: 43px;
  height: 43px;
  background-color: #4470b4;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 28%;
  z-index: 2; }

.button-next {
  right: 5px; }

.button-prev {
  left: 5px; }

/* ====================================== WORKS ITEMS END  ==================================  */
/* ====================================== PROD ITEM START  ==================================  */
.prod__title {
  text-align: center;
  margin-bottom: 40px;
  font: 30px "GothamProMedium", sans-serif;
  color: #000; }

.prodItem__row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.prodItem__block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-basis: 25%;
  margin-bottom: 65px; }

.prodItem__image {
  margin-bottom: 20px; }

.prodItem__name {
  font: 18px "GothamProRegular", sans-serif;
  color: #000000; }

.prodItem__row1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 90px; }

.prodDesc__image {
  max-width: 659px;
  flex-basis: 100%;
  margin-right: 45px; }

.prod__list li {
  position: relative;
  font: 18px "GothamProRegular", sans-serif;
  color: #000;
  margin-bottom: 20px; }
  .prod__list li:before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: #4470b4;
    position: absolute;
    left: -20px;
    top: 5px; }

.prodItem__row2 {
  display: flex;
  justify-content: space-between; }

.prodInfo__image {
  max-width: 659px;
  flex-basis: 100%;
  margin-left: 50px; }

.prodItem__information {
  flex-basis: 100%; }
  .prodItem__information p {
    margin-bottom: 15px; }

.prodItem__lastRow {
  margin-bottom: 60px; }

/* ====================================== PROD ITEM END  ==================================  */
/* ====================================== CONTACTS ITEM START  ==================================  */
#map {
  height: 325px; }

.contacts__row {
  display: flex;
  justify-content: space-between; }

.contacts__title {
  font: 700 24px "GothamProRegular", sans-serif;
  color: #000000;
  margin-bottom: 20px; }

.contacts__list li {
  color: #000;
  font: 24px "GothamProRegular", sans-serif; }
  .contacts__list li span {
    font: 24px "GothamProLight", sans-serif;
    padding-left: 10px; }

.contacts__border {
  border-left: 2px solid #4470b4;
  padding-left: 25px; }

.contacts__address {
  margin-bottom: 50px; }
  .contacts__address li {
    display: flex;
    flex-direction: column;
    font: 24px/24px "GothamProMedium", sans-serif;
    color: #000000;
    margin-bottom: 30px; }
    .contacts__address li span {
      font: 24px/24px "GothamProRegular", sans-serif; }

.contacts__itemFirst {
  flex-basis: 50%; }

.contacts__email {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font: 18px "GothamProRegular", sans-serif;
  width: 389px;
  height: 62px;
  background-color: #4470b4; }

/* ====================================== CONTACTS ITEM END  ==================================  */
/* ====================================== PAYMENTS ITEM START  ==================================  */
.payments p {
  margin-bottom: 30px; }

.payments__title {
  margin-bottom: 25px;
  font: 30px "GothamProMedium", sans-serif;
  color: #000; }

.payments__subTitle {
  margin-bottom: 15px;
  font: 18px "GothamProLight", sans-serif;
  font-style: italic;
  color: #4470b4; }

.payments__row {
  display: flex;
  justify-content: space-between; }
  .payments__row .sendForm {
    max-width: 410px;
    flex-basis: 100%; }

.payments__image {
  max-width: 793px;
  flex-basis: 100%; }

.payments__blockTitle {
  color: #4470b4;
  font: 24px "GothamProRegular", sans-serif;
  margin-bottom: 25px;
  margin-top: 25px; }

label {
  color: #000000;
  font: 16px/30px "GothamProRegular", sans-serif;
  display: flex;
  align-items: center;
  cursor: pointer; }

.jq-radio {
  max-width: 18px;
  height: 18px;
  border: 1px solid #808080;
  background-color: #ffffff;
  margin-right: 10px;
  flex-basis: 100%;
  transition: all .3s ease;
  border-radius: 50%;
  cursor: pointer; }
  .jq-radio.checked {
    box-shadow: inset 0 0 0 4px #fff , inset 0 0 0 9px #4470b4; }

.inputs__list {
  display: flex;
  justify-content: space-between;
  padding: 0 45px;
  flex-wrap: wrap; }
  .inputs__list label {
    flex-basis: 50%;
    color: #a2a2a2;
    font-size: 15px; }
  .inputs__list .jq-checkbox {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 1px solid #808080;
    background-color: #ffffff;
    transition: all .3s ease;
    margin-right: 10px; }
    .inputs__list .jq-checkbox.checked {
      background: url(../img/check.png) no-repeat center, #fff; }

.inputs__calc {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px; }
  .inputs__calc input {
    width: 114px;
    height: 38px;
    border: 1px solid #4470b4;
    background-color: #ffffff;
    font: 18px "GothamProRegular", sans-serif;
    padding: 0 15px;
    color: rgba(0, 0, 0, 0.5); }
  .inputs__calc span {
    font: 14px "GothamProRegular", sans-serif;
    color: #000; }
  .inputs__calc .calc__total {
    color: #b8b8b8;
    font: 16px "GothamProRegular", sans-serif; }
    .inputs__calc .calc__total span {
      font: 700 24px "GothamProRegular", sans-serif;
      color: #000; }
    .inputs__calc .calc__total sup {
      font-size: 16px; }

.payments__blockName {
  text-transform: uppercase;
  padding-left: 20px;
  color: #000;
  font: 20px "GothamProMedium", sans-serif;
  margin-bottom: 10px; }

.payments__blockBottom {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .payments__blockBottom ul {
    flex-basis: 50%;
    margin-bottom: 25px; }

.jq-checkbox {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid #808080;
  background-color: #ffffff;
  transition: all .3s ease;
  margin-right: 10px; }
  .jq-checkbox.checked {
    background: url(../img/check.png) no-repeat center, #fff; }

.payments__amount {
  color: #b8b8b8;
  padding-left: 5px; }

/* ====================================== PAYMENTS ITEM END  ==================================  */
/* ====================================== MODALS START  ==============================  */
.overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: none; }

.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  width: 409px;
  height: 441px;
  display: none; }

.sendForm {
  width: 409px;
  height: 441px;
  border: 2px solid #4470b4;
  background-color: #dde9fb;
  padding: 20px; }
  .sendForm__title {
    text-align: center;
    margin-bottom: 30px;
    font: 24px/30px "GothamProMedium", sans-serif;
    color: #000; }
  .sendForm__info {
    text-align: center;
    font: 12px "GothamProRegular", sans-serif;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .sendForm__info a {
      font: 12px "GothamProRegular", sans-serif;
      color: #0000ff; }
  .sendForm input {
    font: 16px "GothamProRegular", sans-serif;
    color: #aaaaaa;
    height: 53px;
    background-color: #ffffff;
    width: 100%;
    margin-bottom: 5px;
    padding: 0 15px; }
    .sendForm input::placeholder {
      color: #aaaaaa;
      font-style: italic; }
  .sendForm button {
    margin-top: 10px;
    width: 100%;
    height: 52px;
    background-color: #4470b4;
    margin-bottom: 15px;
    font: 20px "GothamProRegular", sans-serif;
    color: #fff;
    text-transform: uppercase;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all .3s ease; }
    .sendForm button:hover {
      transform: scale(0.9); }

/* ====================================== MODALS END  ================================  */
